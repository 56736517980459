<template>
  <v-container v-if="user">
    <v-card class="table-card">
      <v-card-title>
        <v-row class="d-flex justify-space-between align-center mb-2 px-2">
          <div class="d-flex">
            <v-avatar size="100" class="elevation-6 ms-1" :color="color">
              <v-icon dark size="64">
                mdi-account-circle
              </v-icon>
            </v-avatar>
            <div class="table-card-title ms-5 text-h2 d-flex">
              <span class="d-none d-sm-block">{{ $t('user') }}&nbsp;-&nbsp;</span>
              <span class="ms-sm-2">{{ user.name }}</span>
            </div>
          </div>
        </v-row>
      </v-card-title>

      <v-card-text>
        <router-view />
      </v-card-text>
    </v-card>

    <v-card>
      <v-tabs
        v-model="tab"
        background-color="primary"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider />

        <v-tab :to="{ name: 'ProfileDetails' }">
          {{ $t('details') }}
          <v-icon size="30">
            mdi-account-details
          </v-icon>
        </v-tab>

        <v-tab :to="{ name: 'ProfileDevices' }">
          {{ $t('devices') }}
          <v-icon size="30">
            mdi-router-wireless
          </v-icon>
        </v-tab>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'User',

  components: {},

  data: () => ({
    icon: 'md-account-tie',
    color: 'indigo',
    tab: 0,
  }),

  computed: {
    user () {
      return this.$store.getters['user/user']
    },
  },
}
</script>
